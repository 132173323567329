import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
};

export type AddCreditResponse = Credit | RuubyGraphError;

export enum AdditionalPaymentTransactionType {
  ADDITIONAL_PAYMENT = 'ADDITIONAL_PAYMENT',
  TIP = 'TIP'
}

export type Address = {
  __typename?: 'Address';
  address1: Scalars['String']['output'];
  address2?: Maybe<Scalars['String']['output']>;
  postcode: Scalars['String']['output'];
  postcodeDistrict: PostcodeDistrict;
  urn: Scalars['String']['output'];
};

export type AdminFee = {
  __typename?: 'AdminFee';
  amount: Scalars['Float']['output'];
  timeCreated: Scalars['DateTime']['output'];
  timeUpdated: Scalars['DateTime']['output'];
};

/** Error codes that can be returned in responses */
export enum ApiError {
  /** Address it not found */
  ADDRESS_NOT_FOUND = 'ADDRESS_NOT_FOUND',
  /** The customer was not found in our system but the sign in attempt did not contain any personal details */
  APPLE_SIGN_IN_MISSING_DETAILS = 'APPLE_SIGN_IN_MISSING_DETAILS',
  /** Booking address urn is required */
  BOOKING_ADDRESS_REQUIRED = 'BOOKING_ADDRESS_REQUIRED',
  /** Booking is already cancelled */
  BOOKING_ALREADY_CANCELLED = 'BOOKING_ALREADY_CANCELLED',
  /** Booking amount is less than the minimum booking fee */
  BOOKING_AMOUNT_IS_LESS_THAN_MINIMUM = 'BOOKING_AMOUNT_IS_LESS_THAN_MINIMUM',
  /** Booking cancellation failed */
  BOOKING_CANCELLATION_FAILED = 'BOOKING_CANCELLATION_FAILED',
  /** Booking country is not served by therapist */
  BOOKING_COUNTRY_NOT_SERVED = 'BOOKING_COUNTRY_NOT_SERVED',
  /** Booking is out of sync */
  BOOKING_DATA_IS_OUTDATED = 'BOOKING_DATA_IS_OUTDATED',
  /** The status of booking is incompatible with the operation */
  BOOKING_INCOMPATIBLE_STATUS = 'BOOKING_INCOMPATIBLE_STATUS',
  /** Booking is not found */
  BOOKING_NOT_FOUND = 'BOOKING_NOT_FOUND',
  /** Booking does not have outstanding amount */
  BOOKING_NO_OUTSTANDING_AMOUNT = 'BOOKING_NO_OUTSTANDING_AMOUNT',
  /** There is no payment request for a booking or it is expired */
  BOOKING_PAYMENT_REQUEST_NOT_FOUND = 'BOOKING_PAYMENT_REQUEST_NOT_FOUND',
  /** Booking address is not served by therapist */
  BOOKING_POSTCODE_NOT_SERVED = 'BOOKING_POSTCODE_NOT_SERVED',
  /** Therapist is not available at that time */
  BOOKING_SLOT_TAKEN = 'BOOKING_SLOT_TAKEN',
  /** Booking treatments are not supported by therapist */
  BOOKING_TREATMENTS_NOT_SUPPORTED = 'BOOKING_TREATMENTS_NOT_SUPPORTED',
  /** Booking treatments are required */
  BOOKING_TREATMENTS_REQUIRED = 'BOOKING_TREATMENTS_REQUIRED',
  /** Booking update failed */
  BOOKING_UPDATE_FAILED = 'BOOKING_UPDATE_FAILED',
  /** Customer is not found */
  CUSTOMER_NOT_FOUND = 'CUSTOMER_NOT_FOUND',
  /** The customer was not found in our system but the sign in attempt did not contain any personal details */
  FACEBOOK_SIGN_IN_MISSING_DETAILS = 'FACEBOOK_SIGN_IN_MISSING_DETAILS',
  /** A unspecific error occurred */
  GENERAL_ERROR = 'GENERAL_ERROR',
  /** The customer was not found in our system but the sign in attempt did not contain any personal details */
  GOOGLE_SIGN_IN_MISSING_DETAILS = 'GOOGLE_SIGN_IN_MISSING_DETAILS',
  /** The provided start and end dates are either invalid or start is after end date */
  INVALID_DATES = 'INVALID_DATES',
  /** Invalid marketing region for a customer */
  INVALID_MARKETING_REGION = 'INVALID_MARKETING_REGION',
  /** The provided phone number is invalid */
  INVALID_PHONE_NUMBER = 'INVALID_PHONE_NUMBER',
  /** The provided postcode is invalid */
  INVALID_POSTCODE = 'INVALID_POSTCODE',
  /** The request the OTP code was supplied for had expired */
  OTP_EXPIRED = 'OTP_EXPIRED',
  /** The OTP code was incorrect */
  OTP_INVALID_CODE = 'OTP_INVALID_CODE',
  /** The OTP method was invalid */
  OTP_INVALID_METHOD = 'OTP_INVALID_METHOD',
  /** The user has reached the maximum number of OTP attempts */
  OTP_MAX_ATTEMPTS_REACHED = 'OTP_MAX_ATTEMPTS_REACHED',
  /** Payment gateway error */
  PAYMENT_GATEWAY_ERROR = 'PAYMENT_GATEWAY_ERROR',
  /** Promotion is already used */
  PROMOTION_ALREADY_USED = 'PROMOTION_ALREADY_USED',
  /** Cannot use own referral code */
  PROMOTION_CANNOT_USE_OWN_CODE = 'PROMOTION_CANNOT_USE_OWN_CODE',
  /** Promotion has expired */
  PROMOTION_EXPIRED = 'PROMOTION_EXPIRED',
  /** Promotion should be used only with the first booking */
  PROMOTION_FIRST_BOOKING_ONLY = 'PROMOTION_FIRST_BOOKING_ONLY',
  /** Promotion maximum spend error */
  PROMOTION_MAXIMUM_SPEND = 'PROMOTION_MAXIMUM_SPEND',
  /** Promotion minimum spend error */
  PROMOTION_MINIMUM_SPEND = 'PROMOTION_MINIMUM_SPEND',
  /** Promotion is not found */
  PROMOTION_NOT_FOUND = 'PROMOTION_NOT_FOUND',
  /** The email already exists in the database */
  SIGNUP_EMAIL_ALREADY_IN_USE = 'SIGNUP_EMAIL_ALREADY_IN_USE',
  /** The phone number already exists in the database */
  SIGNUP_PHONE_ALREADY_IN_USE = 'SIGNUP_PHONE_ALREADY_IN_USE',
  /** Therapist is not found */
  THERAPIST_NOT_FOUND = 'THERAPIST_NOT_FOUND'
}

/** Permission roles */
export enum AuthRole {
  ADMIN = 'ADMIN',
  CUSTOMER = 'CUSTOMER',
  THERAPIST = 'THERAPIST'
}

/** Authorisation tokens */
export type AuthTokens = {
  __typename?: 'AuthTokens';
  /** An access token to use when making authenticated requests */
  accessToken: Scalars['String']['output'];
};

export type AvailabilitySlot = {
  __typename?: 'AvailabilitySlot';
  end: Scalars['DateTime']['output'];
  start: Scalars['DateTime']['output'];
};

export type BankFee = Financial & {
  __typename?: 'BankFee';
  amount: Scalars['Float']['output'];
  timeCreated: Scalars['DateTime']['output'];
  timeUpdated: Scalars['DateTime']['output'];
};

export type Booking = {
  __typename?: 'Booking';
  address: Address;
  bookingTreatments: Array<BookingTreatment>;
  commission: Scalars['Float']['output'];
  customer: Customer;
  fees: Array<AdminFee>;
  financials: Array<Financial>;
  notes: Scalars['String']['output'];
  originalTherapist: Therapist;
  outstandingAmount: Scalars['Float']['output'];
  paymentTransactions: Array<PaymentTransaction>;
  percentageToTherapist: Scalars['Float']['output'];
  refunds: Array<Refund>;
  review?: Maybe<Review>;
  status: BookingStatus;
  therapist: Therapist;
  tier: Tier;
  timeCreated: Scalars['DateTime']['output'];
  timeEnds: Scalars['DateTime']['output'];
  timeStarts: Scalars['DateTime']['output'];
  timeUpdated: Scalars['DateTime']['output'];
  tips: Array<BookingTip>;
  urn: Scalars['String']['output'];
};

export type BookingAdminTreatmentParams = {
  price: Scalars['Float']['input'];
  urn: Scalars['String']['input'];
};

export type BookingCancelConfirmation = {
  __typename?: 'BookingCancelConfirmation';
  status: Scalars['Boolean']['output'];
};

export type BookingCancelConfirmationResponse = BookingCancelConfirmation | RuubyGraphError;

export enum BookingCancellationReason {
  CUSTOMER = 'CUSTOMER',
  LATE = 'LATE',
  QUALITY = 'QUALITY',
  RUUBY = 'RUUBY',
  THERAPIST = 'THERAPIST'
}

export type BookingFee = {
  __typename?: 'BookingFee';
  amount: Scalars['Float']['output'];
};

export type BookingFeeResponse = BookingFee | RuubyGraphError;

export type BookingPaymentConfirmation = {
  __typename?: 'BookingPaymentConfirmation';
  status: Scalars['Boolean']['output'];
};

export type BookingPaymentConfirmationResponse = BookingPaymentConfirmation | RuubyGraphError;

export type BookingPaymentIntent = {
  __typename?: 'BookingPaymentIntent';
  bookingUrn: Scalars['String']['output'];
  customerStripeId: Scalars['String']['output'];
  ephemeralKey: Scalars['String']['output'];
  paymentIntent: Scalars['String']['output'];
};

export type BookingPaymentIntentResponse = BookingPaymentIntent | RuubyGraphError;

export type BookingRequestPayment = {
  __typename?: 'BookingRequestPayment';
  paymentRequestToken: Scalars['String']['output'];
};

export type BookingRequestPaymentResponse = BookingRequestPayment | RuubyGraphError;

/** Booking response */
export type BookingResponse = Booking | RuubyGraphError;

export enum BookingStatus {
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  CONFIRMED = 'CONFIRMED',
  CREATED = 'CREATED',
  IN_PROGRESS = 'IN_PROGRESS',
  THERAPIST_ON_WAY = 'THERAPIST_ON_WAY',
  UNCONFIRMED = 'UNCONFIRMED'
}

export type BookingTip = {
  __typename?: 'BookingTip';
  amount: Scalars['Float']['output'];
  brand?: Maybe<Scalars['String']['output']>;
  cardType?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Currency>;
  lastFour?: Maybe<Scalars['String']['output']>;
  timeCreated: Scalars['DateTime']['output'];
  timeUpdated: Scalars['DateTime']['output'];
};

export type BookingTreatment = {
  __typename?: 'BookingTreatment';
  price: Scalars['Float']['output'];
  treatment: Treatment;
  urn: Scalars['String']['output'];
};

export type BookingTreatmentParams = {
  quantity: Scalars['Int']['input'];
  urn: Scalars['String']['input'];
};

export type BookingsFilters = {
  appointmentTime?: InputMaybe<DateRange>;
  createdTime?: InputMaybe<DateRange>;
  isFullyPaid?: InputMaybe<Scalars['Boolean']['input']>;
  region?: InputMaybe<Array<InputMaybe<Region>>>;
  status?: InputMaybe<Array<InputMaybe<BookingStatus>>>;
  tier?: InputMaybe<Array<InputMaybe<Tier>>>;
};

/** Booking search response */
export type BookingsResponse = RuubyGraphError | SearchBookingsResultSet;

export type BookingsResultSet = {
  __typename?: 'BookingsResultSet';
  /** The last created date of bookings */
  lastTimeCreated?: Maybe<Scalars['DateTime']['output']>;
  /** Total number of bookings */
  totalCount: Scalars['Int']['output'];
};

export type BookingsSorting = {
  direction?: InputMaybe<Scalars['String']['input']>;
  field: Scalars['String']['input'];
};

export type BraintreeRefund = Refund & {
  __typename?: 'BraintreeRefund';
  amount: Scalars['Float']['output'];
  timeCreated: Scalars['DateTime']['output'];
  timeUpdated: Scalars['DateTime']['output'];
};

export type BraintreeTransaction = PaymentTransaction & {
  __typename?: 'BraintreeTransaction';
  amount: Scalars['Float']['output'];
  braintreeTransactionId: Scalars['String']['output'];
  timeCreated: Scalars['DateTime']['output'];
  timeUpdated: Scalars['DateTime']['output'];
};

export enum Channel {
  ADMIN_PANEL = 'ADMIN_PANEL',
  ANDROID = 'ANDROID',
  IOS = 'IOS',
  WEBSITE = 'WEBSITE'
}

export type ComplimentaryTransaction = PaymentTransaction & {
  __typename?: 'ComplimentaryTransaction';
  amount: Scalars['Float']['output'];
  timeCreated: Scalars['DateTime']['output'];
  timeUpdated: Scalars['DateTime']['output'];
};

/** Contact methods supported for sign in */
export enum ContactMethod {
  APPLE = 'APPLE',
  EMAIL = 'EMAIL',
  FACEBOOK = 'FACEBOOK',
  GOOGLE = 'GOOGLE',
  PHONE = 'PHONE'
}

/** Response when a contact verification token is issued */
export type ContactVerificationToken = {
  __typename?: 'ContactVerificationToken';
  /** The token to represent the validated contact method when registering a user */
  verifiedContactToken?: Maybe<Scalars['String']['output']>;
};

export enum Country {
  CH = 'CH',
  GB = 'GB'
}

export type CountryDetails = {
  __typename?: 'CountryDetails';
  country: Country;
  currency: Currency;
  languageCode: LanguageCode;
};

export type Credit = {
  __typename?: 'Credit';
  customer: Customer;
  expires?: Maybe<Scalars['DateTime']['output']>;
  originalAmount: Scalars['Float']['output'];
  referralBookingUrn?: Maybe<Scalars['String']['output']>;
  remainingAmount: Scalars['Float']['output'];
  timeCreated: Scalars['DateTime']['output'];
  type: CreditType;
  urn: Scalars['String']['output'];
  voucherUrn?: Maybe<Scalars['String']['output']>;
};

export type CreditRefund = Refund & {
  __typename?: 'CreditRefund';
  amount: Scalars['Float']['output'];
  timeCreated: Scalars['DateTime']['output'];
  timeUpdated: Scalars['DateTime']['output'];
};

export type CreditSpend = {
  __typename?: 'CreditSpend';
  amountUsed: Scalars['Float']['output'];
  credit: Credit;
};

export type CreditTransaction = PaymentTransaction & {
  __typename?: 'CreditTransaction';
  amount: Scalars['Float']['output'];
  credit: Credit;
  timeCreated: Scalars['DateTime']['output'];
  timeUpdated: Scalars['DateTime']['output'];
};

export enum CreditType {
  ACQUISITION = 'ACQUISITION',
  COMPLAINT = 'COMPLAINT',
  INFLUENCER = 'INFLUENCER',
  MIGRATED = 'MIGRATED',
  REFERRAL = 'REFERRAL',
  RETENTION = 'RETENTION',
  VOUCHER = 'VOUCHER'
}

export enum Currency {
  CHF = 'CHF',
  GBP = 'GBP'
}

export type Cursors = {
  __typename?: 'Cursors';
  after?: Maybe<Scalars['String']['output']>;
  before?: Maybe<Scalars['String']['output']>;
};

export type Customer = {
  __typename?: 'Customer';
  addresses?: Maybe<Array<Address>>;
  credits: Array<Credit>;
  creditsRemaining: Scalars['Float']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  isBlocked: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  payment: CustomerPayment;
  phone?: Maybe<Scalars['String']['output']>;
  urn: Scalars['String']['output'];
};

export type CustomerPayment = {
  __typename?: 'CustomerPayment';
  paymentMethods: Array<CustomerPaymentMethod>;
  stripeId: Scalars['String']['output'];
};

export type CustomerPaymentMethod = {
  __typename?: 'CustomerPaymentMethod';
  brand: Scalars['String']['output'];
  expirationMonth: Scalars['Int']['output'];
  expirationYear: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  lastFour: Scalars['String']['output'];
};

export type CustomerResponse = Customer | RuubyGraphError;

/** Customer search response */
export type CustomersResponse = CustomersResultSet | RuubyGraphError;

export type CustomersResultSet = {
  __typename?: 'CustomersResultSet';
  cursors: Cursors;
  items: Array<Customer>;
};

export type CustomersSorting = {
  direction?: InputMaybe<Scalars['String']['input']>;
  field: Scalars['String']['input'];
};

export type DateRange = {
  from?: InputMaybe<Scalars['DateTime']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Financial = {
  amount: Scalars['Float']['output'];
  timeCreated: Scalars['DateTime']['output'];
  timeUpdated: Scalars['DateTime']['output'];
};

export type InvoiceTransaction = PaymentTransaction & {
  __typename?: 'InvoiceTransaction';
  amount: Scalars['Float']['output'];
  timeCreated: Scalars['DateTime']['output'];
  timeUpdated: Scalars['DateTime']['output'];
};

export enum LanguageCode {
  DE = 'DE',
  EN = 'EN'
}

export type MerchandisingPrice = {
  __typename?: 'MerchandisingPrice';
  isFromPrice: Scalars['Boolean']['output'];
  price: Scalars['Float']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addCredit: AddCreditResponse;
  cancelBooking?: Maybe<BookingCancelConfirmationResponse>;
  chargeBookingAdditionalPayment?: Maybe<BookingPaymentConfirmationResponse>;
  completeBookingPaymentRequest: PaymentRequestCompleteResponse;
  confirmBookingAdditionalPayment?: Maybe<BookingPaymentConfirmationResponse>;
  confirmBookingPayment?: Maybe<BookingPaymentConfirmationResponse>;
  createBooking: BookingResponse;
  createBookingAdditionalPayment?: Maybe<BookingPaymentConfirmationResponse>;
  /** Request an OTP code to be sent to verify the customer has access to the email address */
  otpEmailRequest: OtpRequestResponse;
  /** Request an OTP code to be sent to verify the customer has access to the contact method */
  otpPhoneRequest: OtpRequestResponse;
  /** Validate a sent OTP code */
  otpValidate: SignInResponse;
  /** Register a new customer that has already validated their email */
  registerCustomerWithValidatedEmail: RegisterCustomerResponse;
  /** Register a new customer that has already validated their phone number */
  registerCustomerWithValidatedPhone: RegisterCustomerResponse;
  /** Request booking payment */
  requestBookingPayment: BookingRequestPaymentResponse;
  reserveBooking?: Maybe<BookingPaymentIntentResponse>;
  signInWithApple: RegisterCustomerResponse;
  signInWithFacebook: SignInResponse;
  signInWithGoogle: SignInResponse;
  tipBooking?: Maybe<BookingPaymentIntentResponse>;
  updateBooking: BookingResponse;
  /** Only used for server to server integration and will be removed in the future */
  useCredit?: Maybe<UseCreditConfirmationResponse>;
};


export type MutationAddCreditArgs = {
  amount: Scalars['Float']['input'];
  currency: Currency;
  customerUrn: Scalars['String']['input'];
  expires?: InputMaybe<Scalars['DateTime']['input']>;
  referralBookingUrn?: InputMaybe<Scalars['String']['input']>;
  type: CreditType;
  voucherUrn?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCancelBookingArgs = {
  bookingUrn: Scalars['String']['input'];
  customerCancellationReason?: InputMaybe<Scalars['String']['input']>;
};


export type MutationChargeBookingAdditionalPaymentArgs = {
  amount: Scalars['Float']['input'];
  bookingUrn: Scalars['String']['input'];
  paymentMethodId: Scalars['String']['input'];
};


export type MutationCompleteBookingPaymentRequestArgs = {
  paymentRequestToken: Scalars['String']['input'];
};


export type MutationConfirmBookingAdditionalPaymentArgs = {
  additionalPaymentTransactionType: AdditionalPaymentTransactionType;
  bookingUrn: Scalars['String']['input'];
  creatorUrn: Scalars['String']['input'];
  paymentAmount: Scalars['Float']['input'];
  paymentIntentId: Scalars['String']['input'];
};


export type MutationConfirmBookingPaymentArgs = {
  bookingUrn: Scalars['String']['input'];
  creatorUrn: Scalars['String']['input'];
  creditsDeduction: Scalars['Float']['input'];
  paymentAmount: Scalars['Float']['input'];
  paymentIntentId: Scalars['String']['input'];
  promotionDeduction: Scalars['Float']['input'];
  promotionUrn?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateBookingArgs = {
  addressUrn?: InputMaybe<Scalars['String']['input']>;
  bookingTreatments: Array<BookingAdminTreatmentParams>;
  customerUrn: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  promoCode?: InputMaybe<Scalars['String']['input']>;
  sendConfirmationEmail: Scalars['Boolean']['input'];
  status: BookingStatus;
  therapistUrn: Scalars['String']['input'];
  tier: Tier;
  timeStarts: Scalars['DateTime']['input'];
};


export type MutationCreateBookingAdditionalPaymentArgs = {
  amount: Scalars['Float']['input'];
  bookingUrn: Scalars['String']['input'];
  paymentMethodId: Scalars['String']['input'];
  returnUrl: Scalars['String']['input'];
};


export type MutationOtpEmailRequestArgs = {
  deviceTime: Scalars['String']['input'];
  email: Scalars['String']['input'];
};


export type MutationOtpPhoneRequestArgs = {
  deviceTime: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};


export type MutationOtpValidateArgs = {
  code: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationRegisterCustomerWithValidatedEmailArgs = {
  agreesToEmailMarketing: Scalars['Boolean']['input'];
  emailValidationToken: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  preferredRegion?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRegisterCustomerWithValidatedPhoneArgs = {
  agreesToEmailMarketing: Scalars['Boolean']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneValidationToken: Scalars['String']['input'];
  preferredRegion?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRequestBookingPaymentArgs = {
  bookingUrn: Scalars['String']['input'];
};


export type MutationReserveBookingArgs = {
  addressUrn?: InputMaybe<Scalars['String']['input']>;
  bookingTreatments: Array<BookingTreatmentParams>;
  channel?: InputMaybe<Channel>;
  notes?: InputMaybe<Scalars['String']['input']>;
  origin?: InputMaybe<Scalars['String']['input']>;
  promoCode?: InputMaybe<Scalars['String']['input']>;
  rakutenSiteId?: InputMaybe<Scalars['String']['input']>;
  rakutenTimeEntered?: InputMaybe<Scalars['DateTime']['input']>;
  saveCard?: InputMaybe<Scalars['Boolean']['input']>;
  therapistUrn: Scalars['String']['input'];
  timeStarts: Scalars['DateTime']['input'];
};


export type MutationSignInWithAppleArgs = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  identityToken: Scalars['String']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSignInWithFacebookArgs = {
  identityToken: Scalars['String']['input'];
};


export type MutationSignInWithGoogleArgs = {
  identityToken: Scalars['String']['input'];
};


export type MutationTipBookingArgs = {
  bookingUrn: Scalars['String']['input'];
  tipAmount: Scalars['Float']['input'];
};


export type MutationUpdateBookingArgs = {
  addressUrn?: InputMaybe<Scalars['String']['input']>;
  bookingUrn: Scalars['String']['input'];
  commission?: InputMaybe<Scalars['Float']['input']>;
  lastTimeUpdated: Scalars['DateTime']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  percentageToTherapist?: InputMaybe<Scalars['Float']['input']>;
  promoCode?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<BookingStatus>;
  therapistUrn?: InputMaybe<Scalars['String']['input']>;
  tier?: InputMaybe<Tier>;
  timeStarts?: InputMaybe<Scalars['DateTime']['input']>;
  updateBookingTreatments?: InputMaybe<Array<BookingAdminTreatmentParams>>;
};


export type MutationUseCreditArgs = {
  amount: Scalars['Float']['input'];
  currency: Currency;
  customerUrn: Scalars['String']['input'];
};

/** Response from the otpRequest mutation */
export type OtpRequestResponse = OtpRequestSuccess | RuubyGraphError;

/** An OTP code was successfully sent */
export type OtpRequestSuccess = {
  __typename?: 'OtpRequestSuccess';
  /** The token to use when validating the OTP code */
  token: Scalars['String']['output'];
};

export type PaymentRequestComplete = {
  __typename?: 'PaymentRequestComplete';
  isSuccess: Scalars['Boolean']['output'];
};

export type PaymentRequestCompleteResponse = PaymentRequestComplete | RuubyGraphError;

export type PaymentRequestedBooking = {
  __typename?: 'PaymentRequestedBooking';
  address: Address;
  bookingTreatments: Array<BookingTreatment>;
  outstandingAmount: Scalars['Float']['output'];
  paymentIntent?: Maybe<Scalars['String']['output']>;
  therapist: Therapist;
  timeEnds: Scalars['DateTime']['output'];
  timeStarts: Scalars['DateTime']['output'];
  urn: Scalars['String']['output'];
};

export type PaymentRequestedBookingResponse = PaymentRequestedBooking | RuubyGraphError;

export type PaymentTransaction = {
  amount: Scalars['Float']['output'];
  timeCreated: Scalars['DateTime']['output'];
  timeUpdated: Scalars['DateTime']['output'];
};

export type PostcodeDistrict = {
  __typename?: 'PostcodeDistrict';
  code: Scalars['String']['output'];
  region: RegionDetails;
  urn: Scalars['String']['output'];
};

/** Promo code validation response */
export type PromoCodeValidationResponse = Promotion | RuubyGraphError;

export type PromoTransaction = PaymentTransaction & {
  __typename?: 'PromoTransaction';
  amount: Scalars['Float']['output'];
  timeCreated: Scalars['DateTime']['output'];
  timeUpdated: Scalars['DateTime']['output'];
};

export type Promotion = {
  __typename?: 'Promotion';
  code: Scalars['String']['output'];
  deduction: Scalars['Float']['output'];
  deductionType: PromotionDeductionType;
  urn: Scalars['String']['output'];
};

export enum PromotionDeductionType {
  ABSOLUTE = 'ABSOLUTE',
  PERCENTAGE = 'PERCENTAGE'
}

export enum PromotionType {
  REFERRAL = 'REFERRAL',
  REFERRAL_REWARD = 'REFERRAL_REWARD',
  VOUCHER = 'VOUCHER'
}

export type Query = {
  __typename?: 'Query';
  booking: BookingResponse;
  /** Get booking fee amount */
  bookingFee: BookingFeeResponse;
  /** Get booking fee description */
  bookingFeeDescription: Scalars['String']['output'];
  bookings: BookingsResponse;
  customer: CustomerResponse;
  customers: CustomersResponse;
  /** Get booking for which payment is requested */
  paymentRequestedBooking: PaymentRequestedBookingResponse;
  /** Current status of the API */
  status: Status;
  /** Get a therapist by URN or slug */
  therapist: TherapistResponse;
  /** Search therapists */
  therapists: TherapistsResponse;
  /** Validates a promoCode */
  validatePromoCode: PromoCodeValidationResponse;
};


export type QueryBookingArgs = {
  id: Scalars['String']['input'];
};


export type QueryBookingFeeArgs = {
  bookingTreatments: Array<InputMaybe<BookingTreatmentParams>>;
  therapistUrn: Scalars['String']['input'];
  timeStarts: Scalars['DateTime']['input'];
};


export type QueryBookingsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<BookingsFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sorting?: InputMaybe<Array<BookingsSorting>>;
};


export type QueryCustomerArgs = {
  urn?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCustomersArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sorting?: InputMaybe<Array<CustomersSorting>>;
};


export type QueryPaymentRequestedBookingArgs = {
  paymentRequestToken: Scalars['String']['input'];
};


export type QueryTherapistArgs = {
  id: Scalars['String']['input'];
};


export type QueryTherapistsArgs = {
  availabilityFromTime?: InputMaybe<Scalars['DateTime']['input']>;
  availabilityToTime?: InputMaybe<Scalars['DateTime']['input']>;
  categoryUrn?: InputMaybe<Scalars['String']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<TherapistsFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  subCategoryUrns?: InputMaybe<Array<Scalars['String']['input']>>;
  treatmentUrn?: InputMaybe<Scalars['String']['input']>;
};


export type QueryValidatePromoCodeArgs = {
  bookingTreatments: Array<BookingTreatmentParams>;
  customerUrn: Scalars['String']['input'];
  promoCode: Scalars['String']['input'];
  therapistUrn: Scalars['String']['input'];
};

export type Refund = {
  amount: Scalars['Float']['output'];
  timeCreated: Scalars['DateTime']['output'];
  timeUpdated: Scalars['DateTime']['output'];
};

export enum Region {
  BRIGHTON = 'BRIGHTON',
  COTSWOLDS = 'COTSWOLDS',
  GB_OTHER = 'GB_OTHER',
  LONDON = 'LONDON',
  MANCHESTER = 'MANCHESTER',
  SURREY = 'SURREY',
  ZURICH = 'ZURICH'
}

export type RegionDetails = {
  __typename?: 'RegionDetails';
  country: CountryDetails;
  region: Region;
  timezone: Scalars['String']['output'];
};

/** Response from the customer registration */
export type RegisterCustomerResponse = AuthTokens | RuubyGraphError;

/** The input data of the customer who has already validated via phone OTP */
export type RegisterCustomerWithEmailInput = {
  agreesToEmailMarketing: Scalars['Boolean']['input'];
  contactValidationToken: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type Review = {
  __typename?: 'Review';
  customer?: Maybe<Customer>;
  rating: Scalars['Int']['output'];
  text?: Maybe<Scalars['String']['output']>;
  urn: Scalars['String']['output'];
};

/** Response for errors */
export type RuubyGraphError = {
  __typename?: 'RuubyGraphError';
  /** The error code */
  error: ApiError;
};

export type RuubyTakings = Financial & {
  __typename?: 'RuubyTakings';
  amount: Scalars['Float']['output'];
  timeCreated: Scalars['DateTime']['output'];
  timeUpdated: Scalars['DateTime']['output'];
};

export type SearchBookingsResultSet = {
  __typename?: 'SearchBookingsResultSet';
  cursors: Cursors;
  items: Array<Booking>;
};

/** Response from customer sign in */
export type SignInResponse = AuthTokens | ContactVerificationToken | RuubyGraphError;

/** Current status of the API */
export type Status = {
  __typename?: 'Status';
  status: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

export type StripeRefund = Refund & {
  __typename?: 'StripeRefund';
  amount: Scalars['Float']['output'];
  timeCreated: Scalars['DateTime']['output'];
  timeUpdated: Scalars['DateTime']['output'];
};

export type StripeTransaction = PaymentTransaction & {
  __typename?: 'StripeTransaction';
  amount: Scalars['Float']['output'];
  brand: Scalars['String']['output'];
  cardType?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Currency>;
  lastFour: Scalars['String']['output'];
  paymentIntentId: Scalars['String']['output'];
  timeCreated: Scalars['DateTime']['output'];
  timeUpdated: Scalars['DateTime']['output'];
};

/** Ruuby therapist */
export type Therapist = {
  __typename?: 'Therapist';
  /** Therapist availability */
  availability: Array<AvailabilitySlot>;
  /** The therapist's avatar image URL */
  avatarImageUrl?: Maybe<Scalars['String']['output']>;
  /** The therapist's bio */
  bio: Scalars['String']['output'];
  /** Bookings for the therapist */
  bookings: BookingsResultSet;
  /** The customer facing name for the therapist */
  displayName: Scalars['String']['output'];
  /** Therapist's PostcodeDistricts */
  districts: Array<PostcodeDistrict>;
  /** Therapist email */
  email?: Maybe<Scalars['String']['output']>;
  /** An array of URLs of the therapist's gallery images */
  galleryImageUrls: Array<Scalars['String']['output']>;
  /** Represents if the therapist is mobile */
  isMobile: Scalars['Boolean']['output'];
  /** The therapist's last booking time */
  lastBookingTime: Scalars['String']['output'];
  /** The therapist full name */
  name: Scalars['String']['output'];
  /** Therapist phone */
  phone?: Maybe<Scalars['String']['output']>;
  /** Therapist's products */
  products?: Maybe<Scalars['String']['output']>;
  /** Reviews for the therapist */
  reviews: TherapistReviewsResultSet;
  /** The therapist's tier */
  tier: TierDetails;
  /** Therapist's treatments */
  treatments: Array<TherapistTreatment>;
  /** The therapist URN */
  urn: Scalars['String']['output'];
  /** The therapist first active workstation */
  workstation?: Maybe<Scalars['Int']['output']>;
};


/** Ruuby therapist */
export type TherapistAvailabilityArgs = {
  end: Scalars['DateTime']['input'];
  ignoreLastBookingTime?: InputMaybe<Scalars['Boolean']['input']>;
  start: Scalars['DateTime']['input'];
};


/** Ruuby therapist */
export type TherapistBookingsArgs = {
  status?: InputMaybe<Array<InputMaybe<BookingStatus>>>;
};


/** Ruuby therapist */
export type TherapistReviewsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
};


/** Ruuby therapist */
export type TherapistTreatmentsArgs = {
  postcode?: InputMaybe<Scalars['String']['input']>;
};

/** Ruuby therapist response */
export type TherapistResponse = RuubyGraphError | Therapist;

export type TherapistReviewsResultSet = {
  __typename?: 'TherapistReviewsResultSet';
  cursor?: Maybe<Scalars['String']['output']>;
  items: Array<Review>;
  totalAverageRating?: Maybe<Scalars['Float']['output']>;
  totalCount: Scalars['Int']['output'];
};

export type TherapistTakings = Financial & {
  __typename?: 'TherapistTakings';
  amount: Scalars['Float']['output'];
  timeCreated: Scalars['DateTime']['output'];
  timeUpdated: Scalars['DateTime']['output'];
};

export type TherapistTreatment = {
  __typename?: 'TherapistTreatment';
  merchandisingPrice: MerchandisingPrice;
  treatment: Treatment;
};

export type TherapistsFilters = {
  availability?: InputMaybe<DateRange>;
  categoryUrn?: InputMaybe<Scalars['String']['input']>;
  /** DEPRECATED: Use filters.postcodes instead */
  postcode?: InputMaybe<Scalars['String']['input']>;
  postcodes?: InputMaybe<Array<Scalars['String']['input']>>;
  region?: InputMaybe<Array<InputMaybe<Region>>>;
  subCategoryUrns?: InputMaybe<Array<Scalars['String']['input']>>;
  tier?: InputMaybe<Array<InputMaybe<Tier>>>;
  treatmentUrn?: InputMaybe<Scalars['String']['input']>;
};

/** Therapist search response */
export type TherapistsResponse = RuubyGraphError | TherapistsResultSet;

export type TherapistsResultSet = {
  __typename?: 'TherapistsResultSet';
  cursors: Cursors;
  items: Array<Therapist>;
};

export enum Tier {
  BLACK_LABEL = 'BLACK_LABEL',
  CLASSIC = 'CLASSIC',
  ELITE = 'ELITE',
  HOSPITALITY = 'HOSPITALITY',
  ON_DEMAND = 'ON_DEMAND',
  PRODUCTIONS = 'PRODUCTIONS'
}

/** Therapist tier */
export type TierDetails = {
  __typename?: 'TierDetails';
  /** The tier description */
  description: Scalars['String']['output'];
  /** The tier name */
  name: Scalars['String']['output'];
  /** The tier type */
  tier: Tier;
};

export type Treatment = {
  __typename?: 'Treatment';
  categories: Array<TreatmentCategory>;
  description?: Maybe<Scalars['String']['output']>;
  duration: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  urn: Scalars['String']['output'];
};

export type TreatmentCategory = {
  __typename?: 'TreatmentCategory';
  name: Scalars['String']['output'];
  parent?: Maybe<TreatmentCategory>;
  urn: Scalars['String']['output'];
};

export type UseCreditConfirmation = {
  __typename?: 'UseCreditConfirmation';
  creditUsed: Array<CreditSpend>;
};

export type UseCreditConfirmationResponse = RuubyGraphError | UseCreditConfirmation;

export type ReserveBookingMutationVariables = Exact<{
  therapistUrn: Scalars['String']['input'];
  bookingTreatments: Array<BookingTreatmentParams> | BookingTreatmentParams;
  timeStarts: Scalars['DateTime']['input'];
  origin: Scalars['String']['input'];
  promoCode?: InputMaybe<Scalars['String']['input']>;
  addressUrn?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  saveCard?: InputMaybe<Scalars['Boolean']['input']>;
  rakutenSiteId?: InputMaybe<Scalars['String']['input']>;
  rakutenTimeEntered?: InputMaybe<Scalars['DateTime']['input']>;
}>;


export type ReserveBookingMutation = { __typename?: 'Mutation', reserveBooking?: { __typename?: 'BookingPaymentIntent', bookingUrn: string, customerStripeId: string, paymentIntent: string, ephemeralKey: string } | { __typename?: 'RuubyGraphError', error: ApiError } | null };

export type BookingFeeQueryVariables = Exact<{
  timeStarts: Scalars['DateTime']['input'];
  therapistUrn: Scalars['String']['input'];
  bookingTreatments: Array<InputMaybe<BookingTreatmentParams>> | InputMaybe<BookingTreatmentParams>;
}>;


export type BookingFeeQuery = { __typename?: 'Query', bookingFeeDescription: string, bookingFee: { __typename?: 'BookingFee', amount: number } | { __typename?: 'RuubyGraphError', error: ApiError } };

export type OtpEmailRequestMutationVariables = Exact<{
  email: Scalars['String']['input'];
  deviceTime: Scalars['String']['input'];
}>;


export type OtpEmailRequestMutation = { __typename?: 'Mutation', otpEmailRequest: { __typename: 'OtpRequestSuccess', token: string } | { __typename: 'RuubyGraphError', error: ApiError } };

export type OtpPhoneRequestMutationVariables = Exact<{
  phone: Scalars['String']['input'];
  deviceTime: Scalars['String']['input'];
}>;


export type OtpPhoneRequestMutation = { __typename?: 'Mutation', otpPhoneRequest: { __typename: 'OtpRequestSuccess', token: string } | { __typename: 'RuubyGraphError', error: ApiError } };

export type RegisterCustomerWithValidatedPhoneMutationVariables = Exact<{
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  agreesToEmailMarketing: Scalars['Boolean']['input'];
  phoneValidationToken: Scalars['String']['input'];
  preferredRegion?: InputMaybe<Scalars['String']['input']>;
}>;


export type RegisterCustomerWithValidatedPhoneMutation = { __typename?: 'Mutation', registerCustomerWithValidatedPhone: { __typename: 'AuthTokens', accessToken: string } | { __typename: 'RuubyGraphError', error: ApiError } };

export type RegisterCustomerWithValidatedEmailMutationVariables = Exact<{
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  agreesToEmailMarketing: Scalars['Boolean']['input'];
  emailValidationToken: Scalars['String']['input'];
  preferredRegion?: InputMaybe<Scalars['String']['input']>;
}>;


export type RegisterCustomerWithValidatedEmailMutation = { __typename?: 'Mutation', registerCustomerWithValidatedEmail: { __typename: 'AuthTokens', accessToken: string } | { __typename: 'RuubyGraphError', error: ApiError } };

export type SignInWithGoogleMutationVariables = Exact<{
  identityToken: Scalars['String']['input'];
}>;


export type SignInWithGoogleMutation = { __typename?: 'Mutation', signInWithGoogle: { __typename: 'AuthTokens', accessToken: string } | { __typename: 'ContactVerificationToken', verifiedContactToken?: string | null } | { __typename: 'RuubyGraphError', error: ApiError } };

export type OtpValidateMutationVariables = Exact<{
  token: Scalars['String']['input'];
  code: Scalars['String']['input'];
}>;


export type OtpValidateMutation = { __typename?: 'Mutation', otpValidate: { __typename: 'AuthTokens', accessToken: string } | { __typename: 'ContactVerificationToken', verifiedContactToken?: string | null } | { __typename: 'RuubyGraphError', error: ApiError } };

export type PaymentRequestedBookingQueryVariables = Exact<{
  paymentRequestToken: Scalars['String']['input'];
}>;


export type PaymentRequestedBookingQuery = { __typename?: 'Query', paymentRequestedBooking: { __typename: 'PaymentRequestedBooking', timeStarts: any, outstandingAmount: number, paymentIntent?: string | null, therapist: { __typename?: 'Therapist', displayName: string }, bookingTreatments: Array<{ __typename?: 'BookingTreatment', price: number, treatment: { __typename?: 'Treatment', urn: string, name: string, duration: number, categories: Array<{ __typename?: 'TreatmentCategory', urn: string, name: string, parent?: { __typename?: 'TreatmentCategory', urn: string, name: string } | null }> } }>, address: { __typename?: 'Address', postcodeDistrict: { __typename?: 'PostcodeDistrict', region: { __typename?: 'RegionDetails', timezone: string, country: { __typename?: 'CountryDetails', country: Country, currency: Currency, languageCode: LanguageCode } } } } } | { __typename: 'RuubyGraphError', error: ApiError } };

export type TherapistQueryVariables = Exact<{
  therapistId: Scalars['String']['input'];
  status?: InputMaybe<Array<InputMaybe<BookingStatus>> | InputMaybe<BookingStatus>>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  start: Scalars['DateTime']['input'];
  end: Scalars['DateTime']['input'];
  ignoreLastBookingTime?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type TherapistQuery = { __typename?: 'Query', therapist: { __typename: 'RuubyGraphError', error: ApiError } | { __typename: 'Therapist', urn: string, workstation?: number | null, displayName: string, avatarImageUrl?: string | null, galleryImageUrls: Array<string>, bio: string, lastBookingTime: string, products?: string | null, isMobile: boolean, tier: { __typename?: 'TierDetails', tier: Tier, name: string, description: string }, reviews: { __typename?: 'TherapistReviewsResultSet', cursor?: string | null, totalCount: number, totalAverageRating?: number | null, items: Array<{ __typename?: 'Review', urn: string, rating: number, text?: string | null, customer?: { __typename?: 'Customer', firstName: string } | null }> }, bookings: { __typename?: 'BookingsResultSet', totalCount: number, lastTimeCreated?: any | null }, treatments: Array<{ __typename?: 'TherapistTreatment', treatment: { __typename?: 'Treatment', urn: string, name: string, duration: number, description?: string | null, categories: Array<{ __typename?: 'TreatmentCategory', urn: string, name: string, parent?: { __typename?: 'TreatmentCategory', urn: string, name: string } | null }> }, merchandisingPrice: { __typename?: 'MerchandisingPrice', price: number, isFromPrice: boolean } }>, availability: Array<{ __typename?: 'AvailabilitySlot', start: any, end: any }>, districts: Array<{ __typename?: 'PostcodeDistrict', code: string }> } };

export type TherapistReviewsQueryVariables = Exact<{
  therapistId: Scalars['String']['input'];
  cursor?: InputMaybe<Scalars['String']['input']>;
}>;


export type TherapistReviewsQuery = { __typename?: 'Query', therapist: { __typename: 'RuubyGraphError', error: ApiError } | { __typename: 'Therapist', reviews: { __typename?: 'TherapistReviewsResultSet', cursor?: string | null, items: Array<{ __typename?: 'Review', urn: string, rating: number, text?: string | null, customer?: { __typename?: 'Customer', firstName: string } | null }> } } };

export type TherapistsQueryVariables = Exact<{
  postcode?: InputMaybe<Scalars['String']['input']>;
  categoryUrn?: InputMaybe<Scalars['String']['input']>;
  treatmentUrn?: InputMaybe<Scalars['String']['input']>;
  availabilityFromTime: Scalars['DateTime']['input'];
  availabilityToTime: Scalars['DateTime']['input'];
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Array<InputMaybe<BookingStatus>> | InputMaybe<BookingStatus>>;
}>;


export type TherapistsQuery = { __typename?: 'Query', therapists: { __typename: 'RuubyGraphError', error: ApiError } | { __typename: 'TherapistsResultSet', items: Array<{ __typename?: 'Therapist', urn: string, displayName: string, avatarImageUrl?: string | null, tier: { __typename?: 'TierDetails', tier: Tier }, reviews: { __typename?: 'TherapistReviewsResultSet', totalCount: number, totalAverageRating?: number | null }, bookings: { __typename?: 'BookingsResultSet', totalCount: number, lastTimeCreated?: any | null }, treatments: Array<{ __typename?: 'TherapistTreatment', treatment: { __typename?: 'Treatment', categories: Array<{ __typename?: 'TreatmentCategory', name: string }> } }>, availability: Array<{ __typename?: 'AvailabilitySlot', start: any, end: any }> }> } };

export type SavedPaymentMethodsQueryVariables = Exact<{ [key: string]: never; }>;


export type SavedPaymentMethodsQuery = { __typename?: 'Query', customer: { __typename?: 'Customer', payment: { __typename?: 'CustomerPayment', paymentMethods: Array<{ __typename?: 'CustomerPaymentMethod', brand: string, expirationMonth: number, expirationYear: number, id: string, lastFour: string }> } } | { __typename?: 'RuubyGraphError', error: ApiError } };


export const ReserveBookingDocument = gql`
    mutation ReserveBooking($therapistUrn: String!, $bookingTreatments: [BookingTreatmentParams!]!, $timeStarts: DateTime!, $origin: String!, $promoCode: String, $addressUrn: String, $notes: String, $saveCard: Boolean, $rakutenSiteId: String, $rakutenTimeEntered: DateTime) {
  reserveBooking(
    therapistUrn: $therapistUrn
    bookingTreatments: $bookingTreatments
    timeStarts: $timeStarts
    origin: $origin
    promoCode: $promoCode
    addressUrn: $addressUrn
    notes: $notes
    saveCard: $saveCard
    rakutenSiteId: $rakutenSiteId
    rakutenTimeEntered: $rakutenTimeEntered
  ) {
    ... on BookingPaymentIntent {
      bookingUrn
      customerStripeId
      paymentIntent
      ephemeralKey
    }
    ... on RuubyGraphError {
      error
    }
  }
}
    `;
export type ReserveBookingMutationFn = Apollo.MutationFunction<ReserveBookingMutation, ReserveBookingMutationVariables>;

/**
 * __useReserveBookingMutation__
 *
 * To run a mutation, you first call `useReserveBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReserveBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reserveBookingMutation, { data, loading, error }] = useReserveBookingMutation({
 *   variables: {
 *      therapistUrn: // value for 'therapistUrn'
 *      bookingTreatments: // value for 'bookingTreatments'
 *      timeStarts: // value for 'timeStarts'
 *      origin: // value for 'origin'
 *      promoCode: // value for 'promoCode'
 *      addressUrn: // value for 'addressUrn'
 *      notes: // value for 'notes'
 *      saveCard: // value for 'saveCard'
 *      rakutenSiteId: // value for 'rakutenSiteId'
 *      rakutenTimeEntered: // value for 'rakutenTimeEntered'
 *   },
 * });
 */
export function useReserveBookingMutation(baseOptions?: Apollo.MutationHookOptions<ReserveBookingMutation, ReserveBookingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReserveBookingMutation, ReserveBookingMutationVariables>(ReserveBookingDocument, options);
      }
export type ReserveBookingMutationHookResult = ReturnType<typeof useReserveBookingMutation>;
export type ReserveBookingMutationResult = Apollo.MutationResult<ReserveBookingMutation>;
export type ReserveBookingMutationOptions = Apollo.BaseMutationOptions<ReserveBookingMutation, ReserveBookingMutationVariables>;
export const BookingFeeDocument = gql`
    query BookingFee($timeStarts: DateTime!, $therapistUrn: String!, $bookingTreatments: [BookingTreatmentParams]!) {
  bookingFeeDescription
  bookingFee(
    timeStarts: $timeStarts
    therapistUrn: $therapistUrn
    bookingTreatments: $bookingTreatments
  ) {
    ... on BookingFee {
      amount
    }
    ... on RuubyGraphError {
      error
    }
  }
}
    `;

/**
 * __useBookingFeeQuery__
 *
 * To run a query within a React component, call `useBookingFeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingFeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingFeeQuery({
 *   variables: {
 *      timeStarts: // value for 'timeStarts'
 *      therapistUrn: // value for 'therapistUrn'
 *      bookingTreatments: // value for 'bookingTreatments'
 *   },
 * });
 */
export function useBookingFeeQuery(baseOptions: Apollo.QueryHookOptions<BookingFeeQuery, BookingFeeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookingFeeQuery, BookingFeeQueryVariables>(BookingFeeDocument, options);
      }
export function useBookingFeeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookingFeeQuery, BookingFeeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookingFeeQuery, BookingFeeQueryVariables>(BookingFeeDocument, options);
        }
export type BookingFeeQueryHookResult = ReturnType<typeof useBookingFeeQuery>;
export type BookingFeeLazyQueryHookResult = ReturnType<typeof useBookingFeeLazyQuery>;
export type BookingFeeQueryResult = Apollo.QueryResult<BookingFeeQuery, BookingFeeQueryVariables>;
export const OtpEmailRequestDocument = gql`
    mutation OtpEmailRequest($email: String!, $deviceTime: String!) {
  otpEmailRequest(email: $email, deviceTime: $deviceTime) {
    __typename
    ... on OtpRequestSuccess {
      token
    }
    ... on RuubyGraphError {
      error
    }
  }
}
    `;
export type OtpEmailRequestMutationFn = Apollo.MutationFunction<OtpEmailRequestMutation, OtpEmailRequestMutationVariables>;

/**
 * __useOtpEmailRequestMutation__
 *
 * To run a mutation, you first call `useOtpEmailRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOtpEmailRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [otpEmailRequestMutation, { data, loading, error }] = useOtpEmailRequestMutation({
 *   variables: {
 *      email: // value for 'email'
 *      deviceTime: // value for 'deviceTime'
 *   },
 * });
 */
export function useOtpEmailRequestMutation(baseOptions?: Apollo.MutationHookOptions<OtpEmailRequestMutation, OtpEmailRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OtpEmailRequestMutation, OtpEmailRequestMutationVariables>(OtpEmailRequestDocument, options);
      }
export type OtpEmailRequestMutationHookResult = ReturnType<typeof useOtpEmailRequestMutation>;
export type OtpEmailRequestMutationResult = Apollo.MutationResult<OtpEmailRequestMutation>;
export type OtpEmailRequestMutationOptions = Apollo.BaseMutationOptions<OtpEmailRequestMutation, OtpEmailRequestMutationVariables>;
export const OtpPhoneRequestDocument = gql`
    mutation OtpPhoneRequest($phone: String!, $deviceTime: String!) {
  otpPhoneRequest(phone: $phone, deviceTime: $deviceTime) {
    __typename
    ... on OtpRequestSuccess {
      token
    }
    ... on RuubyGraphError {
      error
    }
  }
}
    `;
export type OtpPhoneRequestMutationFn = Apollo.MutationFunction<OtpPhoneRequestMutation, OtpPhoneRequestMutationVariables>;

/**
 * __useOtpPhoneRequestMutation__
 *
 * To run a mutation, you first call `useOtpPhoneRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOtpPhoneRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [otpPhoneRequestMutation, { data, loading, error }] = useOtpPhoneRequestMutation({
 *   variables: {
 *      phone: // value for 'phone'
 *      deviceTime: // value for 'deviceTime'
 *   },
 * });
 */
export function useOtpPhoneRequestMutation(baseOptions?: Apollo.MutationHookOptions<OtpPhoneRequestMutation, OtpPhoneRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OtpPhoneRequestMutation, OtpPhoneRequestMutationVariables>(OtpPhoneRequestDocument, options);
      }
export type OtpPhoneRequestMutationHookResult = ReturnType<typeof useOtpPhoneRequestMutation>;
export type OtpPhoneRequestMutationResult = Apollo.MutationResult<OtpPhoneRequestMutation>;
export type OtpPhoneRequestMutationOptions = Apollo.BaseMutationOptions<OtpPhoneRequestMutation, OtpPhoneRequestMutationVariables>;
export const RegisterCustomerWithValidatedPhoneDocument = gql`
    mutation RegisterCustomerWithValidatedPhone($firstName: String!, $lastName: String!, $email: String!, $agreesToEmailMarketing: Boolean!, $phoneValidationToken: String!, $preferredRegion: String) {
  registerCustomerWithValidatedPhone(
    firstName: $firstName
    lastName: $lastName
    email: $email
    agreesToEmailMarketing: $agreesToEmailMarketing
    phoneValidationToken: $phoneValidationToken
    preferredRegion: $preferredRegion
  ) {
    __typename
    ... on AuthTokens {
      accessToken
    }
    ... on RuubyGraphError {
      error
    }
  }
}
    `;
export type RegisterCustomerWithValidatedPhoneMutationFn = Apollo.MutationFunction<RegisterCustomerWithValidatedPhoneMutation, RegisterCustomerWithValidatedPhoneMutationVariables>;

/**
 * __useRegisterCustomerWithValidatedPhoneMutation__
 *
 * To run a mutation, you first call `useRegisterCustomerWithValidatedPhoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterCustomerWithValidatedPhoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerCustomerWithValidatedPhoneMutation, { data, loading, error }] = useRegisterCustomerWithValidatedPhoneMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      agreesToEmailMarketing: // value for 'agreesToEmailMarketing'
 *      phoneValidationToken: // value for 'phoneValidationToken'
 *      preferredRegion: // value for 'preferredRegion'
 *   },
 * });
 */
export function useRegisterCustomerWithValidatedPhoneMutation(baseOptions?: Apollo.MutationHookOptions<RegisterCustomerWithValidatedPhoneMutation, RegisterCustomerWithValidatedPhoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterCustomerWithValidatedPhoneMutation, RegisterCustomerWithValidatedPhoneMutationVariables>(RegisterCustomerWithValidatedPhoneDocument, options);
      }
export type RegisterCustomerWithValidatedPhoneMutationHookResult = ReturnType<typeof useRegisterCustomerWithValidatedPhoneMutation>;
export type RegisterCustomerWithValidatedPhoneMutationResult = Apollo.MutationResult<RegisterCustomerWithValidatedPhoneMutation>;
export type RegisterCustomerWithValidatedPhoneMutationOptions = Apollo.BaseMutationOptions<RegisterCustomerWithValidatedPhoneMutation, RegisterCustomerWithValidatedPhoneMutationVariables>;
export const RegisterCustomerWithValidatedEmailDocument = gql`
    mutation RegisterCustomerWithValidatedEmail($firstName: String!, $lastName: String!, $phone: String!, $agreesToEmailMarketing: Boolean!, $emailValidationToken: String!, $preferredRegion: String) {
  registerCustomerWithValidatedEmail(
    firstName: $firstName
    lastName: $lastName
    phone: $phone
    agreesToEmailMarketing: $agreesToEmailMarketing
    emailValidationToken: $emailValidationToken
    preferredRegion: $preferredRegion
  ) {
    __typename
    ... on AuthTokens {
      accessToken
    }
    ... on RuubyGraphError {
      error
    }
  }
}
    `;
export type RegisterCustomerWithValidatedEmailMutationFn = Apollo.MutationFunction<RegisterCustomerWithValidatedEmailMutation, RegisterCustomerWithValidatedEmailMutationVariables>;

/**
 * __useRegisterCustomerWithValidatedEmailMutation__
 *
 * To run a mutation, you first call `useRegisterCustomerWithValidatedEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterCustomerWithValidatedEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerCustomerWithValidatedEmailMutation, { data, loading, error }] = useRegisterCustomerWithValidatedEmailMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      phone: // value for 'phone'
 *      agreesToEmailMarketing: // value for 'agreesToEmailMarketing'
 *      emailValidationToken: // value for 'emailValidationToken'
 *      preferredRegion: // value for 'preferredRegion'
 *   },
 * });
 */
export function useRegisterCustomerWithValidatedEmailMutation(baseOptions?: Apollo.MutationHookOptions<RegisterCustomerWithValidatedEmailMutation, RegisterCustomerWithValidatedEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterCustomerWithValidatedEmailMutation, RegisterCustomerWithValidatedEmailMutationVariables>(RegisterCustomerWithValidatedEmailDocument, options);
      }
export type RegisterCustomerWithValidatedEmailMutationHookResult = ReturnType<typeof useRegisterCustomerWithValidatedEmailMutation>;
export type RegisterCustomerWithValidatedEmailMutationResult = Apollo.MutationResult<RegisterCustomerWithValidatedEmailMutation>;
export type RegisterCustomerWithValidatedEmailMutationOptions = Apollo.BaseMutationOptions<RegisterCustomerWithValidatedEmailMutation, RegisterCustomerWithValidatedEmailMutationVariables>;
export const SignInWithGoogleDocument = gql`
    mutation SignInWithGoogle($identityToken: String!) {
  signInWithGoogle(identityToken: $identityToken) {
    __typename
    ... on AuthTokens {
      accessToken
    }
    ... on ContactVerificationToken {
      verifiedContactToken
    }
    ... on RuubyGraphError {
      error
    }
  }
}
    `;
export type SignInWithGoogleMutationFn = Apollo.MutationFunction<SignInWithGoogleMutation, SignInWithGoogleMutationVariables>;

/**
 * __useSignInWithGoogleMutation__
 *
 * To run a mutation, you first call `useSignInWithGoogleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInWithGoogleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInWithGoogleMutation, { data, loading, error }] = useSignInWithGoogleMutation({
 *   variables: {
 *      identityToken: // value for 'identityToken'
 *   },
 * });
 */
export function useSignInWithGoogleMutation(baseOptions?: Apollo.MutationHookOptions<SignInWithGoogleMutation, SignInWithGoogleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignInWithGoogleMutation, SignInWithGoogleMutationVariables>(SignInWithGoogleDocument, options);
      }
export type SignInWithGoogleMutationHookResult = ReturnType<typeof useSignInWithGoogleMutation>;
export type SignInWithGoogleMutationResult = Apollo.MutationResult<SignInWithGoogleMutation>;
export type SignInWithGoogleMutationOptions = Apollo.BaseMutationOptions<SignInWithGoogleMutation, SignInWithGoogleMutationVariables>;
export const OtpValidateDocument = gql`
    mutation OtpValidate($token: String!, $code: String!) {
  otpValidate(token: $token, code: $code) {
    __typename
    ... on ContactVerificationToken {
      verifiedContactToken
    }
    ... on AuthTokens {
      accessToken
    }
    ... on RuubyGraphError {
      error
    }
  }
}
    `;
export type OtpValidateMutationFn = Apollo.MutationFunction<OtpValidateMutation, OtpValidateMutationVariables>;

/**
 * __useOtpValidateMutation__
 *
 * To run a mutation, you first call `useOtpValidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOtpValidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [otpValidateMutation, { data, loading, error }] = useOtpValidateMutation({
 *   variables: {
 *      token: // value for 'token'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useOtpValidateMutation(baseOptions?: Apollo.MutationHookOptions<OtpValidateMutation, OtpValidateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OtpValidateMutation, OtpValidateMutationVariables>(OtpValidateDocument, options);
      }
export type OtpValidateMutationHookResult = ReturnType<typeof useOtpValidateMutation>;
export type OtpValidateMutationResult = Apollo.MutationResult<OtpValidateMutation>;
export type OtpValidateMutationOptions = Apollo.BaseMutationOptions<OtpValidateMutation, OtpValidateMutationVariables>;
export const PaymentRequestedBookingDocument = gql`
    query PaymentRequestedBooking($paymentRequestToken: String!) {
  paymentRequestedBooking(paymentRequestToken: $paymentRequestToken) {
    __typename
    ... on PaymentRequestedBooking {
      timeStarts
      therapist {
        displayName
      }
      bookingTreatments {
        price
        treatment {
          urn
          name
          duration
          categories {
            urn
            name
            parent {
              urn
              name
            }
          }
        }
      }
      outstandingAmount
      paymentIntent
      address {
        postcodeDistrict {
          region {
            country {
              country
              currency
              languageCode
            }
            timezone
          }
        }
      }
    }
    ... on RuubyGraphError {
      error
    }
  }
}
    `;

/**
 * __usePaymentRequestedBookingQuery__
 *
 * To run a query within a React component, call `usePaymentRequestedBookingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentRequestedBookingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentRequestedBookingQuery({
 *   variables: {
 *      paymentRequestToken: // value for 'paymentRequestToken'
 *   },
 * });
 */
export function usePaymentRequestedBookingQuery(baseOptions: Apollo.QueryHookOptions<PaymentRequestedBookingQuery, PaymentRequestedBookingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentRequestedBookingQuery, PaymentRequestedBookingQueryVariables>(PaymentRequestedBookingDocument, options);
      }
export function usePaymentRequestedBookingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentRequestedBookingQuery, PaymentRequestedBookingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentRequestedBookingQuery, PaymentRequestedBookingQueryVariables>(PaymentRequestedBookingDocument, options);
        }
export type PaymentRequestedBookingQueryHookResult = ReturnType<typeof usePaymentRequestedBookingQuery>;
export type PaymentRequestedBookingLazyQueryHookResult = ReturnType<typeof usePaymentRequestedBookingLazyQuery>;
export type PaymentRequestedBookingQueryResult = Apollo.QueryResult<PaymentRequestedBookingQuery, PaymentRequestedBookingQueryVariables>;
export const TherapistDocument = gql`
    query Therapist($therapistId: String!, $status: [BookingStatus], $postcode: String, $cursor: String, $start: DateTime!, $end: DateTime!, $ignoreLastBookingTime: Boolean) {
  therapist(id: $therapistId) {
    __typename
    ... on Therapist {
      urn
      workstation
      displayName
      avatarImageUrl
      galleryImageUrls
      bio
      lastBookingTime
      tier {
        tier
        name
        description
      }
      reviews(cursor: $cursor) {
        cursor
        totalCount
        totalAverageRating
        items {
          urn
          customer {
            firstName
          }
          rating
          text
        }
      }
      bookings(status: $status) {
        totalCount
        lastTimeCreated
      }
      treatments(postcode: $postcode) {
        treatment {
          urn
          name
          duration
          description
          categories {
            urn
            name
            parent {
              urn
              name
            }
          }
        }
        merchandisingPrice {
          price
          isFromPrice
        }
      }
      products
      availability(
        start: $start
        end: $end
        ignoreLastBookingTime: $ignoreLastBookingTime
      ) {
        start
        end
      }
      districts {
        code
      }
      isMobile
    }
    ... on RuubyGraphError {
      error
    }
  }
}
    `;

/**
 * __useTherapistQuery__
 *
 * To run a query within a React component, call `useTherapistQuery` and pass it any options that fit your needs.
 * When your component renders, `useTherapistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTherapistQuery({
 *   variables: {
 *      therapistId: // value for 'therapistId'
 *      status: // value for 'status'
 *      postcode: // value for 'postcode'
 *      cursor: // value for 'cursor'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      ignoreLastBookingTime: // value for 'ignoreLastBookingTime'
 *   },
 * });
 */
export function useTherapistQuery(baseOptions: Apollo.QueryHookOptions<TherapistQuery, TherapistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TherapistQuery, TherapistQueryVariables>(TherapistDocument, options);
      }
export function useTherapistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TherapistQuery, TherapistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TherapistQuery, TherapistQueryVariables>(TherapistDocument, options);
        }
export type TherapistQueryHookResult = ReturnType<typeof useTherapistQuery>;
export type TherapistLazyQueryHookResult = ReturnType<typeof useTherapistLazyQuery>;
export type TherapistQueryResult = Apollo.QueryResult<TherapistQuery, TherapistQueryVariables>;
export const TherapistReviewsDocument = gql`
    query TherapistReviews($therapistId: String!, $cursor: String) {
  therapist(id: $therapistId) {
    __typename
    ... on Therapist {
      reviews(cursor: $cursor) {
        cursor
        items {
          urn
          customer {
            firstName
          }
          rating
          text
        }
      }
    }
    ... on RuubyGraphError {
      error
    }
  }
}
    `;

/**
 * __useTherapistReviewsQuery__
 *
 * To run a query within a React component, call `useTherapistReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTherapistReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTherapistReviewsQuery({
 *   variables: {
 *      therapistId: // value for 'therapistId'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useTherapistReviewsQuery(baseOptions: Apollo.QueryHookOptions<TherapistReviewsQuery, TherapistReviewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TherapistReviewsQuery, TherapistReviewsQueryVariables>(TherapistReviewsDocument, options);
      }
export function useTherapistReviewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TherapistReviewsQuery, TherapistReviewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TherapistReviewsQuery, TherapistReviewsQueryVariables>(TherapistReviewsDocument, options);
        }
export type TherapistReviewsQueryHookResult = ReturnType<typeof useTherapistReviewsQuery>;
export type TherapistReviewsLazyQueryHookResult = ReturnType<typeof useTherapistReviewsLazyQuery>;
export type TherapistReviewsQueryResult = Apollo.QueryResult<TherapistReviewsQuery, TherapistReviewsQueryVariables>;
export const TherapistsDocument = gql`
    query Therapists($postcode: String, $categoryUrn: String, $treatmentUrn: String, $availabilityFromTime: DateTime!, $availabilityToTime: DateTime!, $cursor: String, $limit: Int, $status: [BookingStatus]) {
  therapists(
    postcode: $postcode
    categoryUrn: $categoryUrn
    treatmentUrn: $treatmentUrn
    availabilityFromTime: $availabilityFromTime
    availabilityToTime: $availabilityToTime
    cursor: $cursor
    limit: $limit
  ) {
    __typename
    ... on TherapistsResultSet {
      items {
        urn
        displayName
        avatarImageUrl
        tier {
          tier
        }
        reviews {
          totalCount
          totalAverageRating
        }
        bookings(status: $status) {
          totalCount
          lastTimeCreated
        }
        treatments(postcode: $postcode) {
          treatment {
            categories {
              name
            }
          }
        }
        availability(start: $availabilityFromTime, end: $availabilityToTime) {
          start
          end
        }
      }
    }
    ... on RuubyGraphError {
      error
    }
  }
}
    `;

/**
 * __useTherapistsQuery__
 *
 * To run a query within a React component, call `useTherapistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTherapistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTherapistsQuery({
 *   variables: {
 *      postcode: // value for 'postcode'
 *      categoryUrn: // value for 'categoryUrn'
 *      treatmentUrn: // value for 'treatmentUrn'
 *      availabilityFromTime: // value for 'availabilityFromTime'
 *      availabilityToTime: // value for 'availabilityToTime'
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useTherapistsQuery(baseOptions: Apollo.QueryHookOptions<TherapistsQuery, TherapistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TherapistsQuery, TherapistsQueryVariables>(TherapistsDocument, options);
      }
export function useTherapistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TherapistsQuery, TherapistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TherapistsQuery, TherapistsQueryVariables>(TherapistsDocument, options);
        }
export type TherapistsQueryHookResult = ReturnType<typeof useTherapistsQuery>;
export type TherapistsLazyQueryHookResult = ReturnType<typeof useTherapistsLazyQuery>;
export type TherapistsQueryResult = Apollo.QueryResult<TherapistsQuery, TherapistsQueryVariables>;
export const SavedPaymentMethodsDocument = gql`
    query SavedPaymentMethods {
  customer {
    ... on Customer {
      payment {
        paymentMethods {
          brand
          expirationMonth
          expirationYear
          id
          lastFour
        }
      }
    }
    ... on RuubyGraphError {
      error
    }
  }
}
    `;

/**
 * __useSavedPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useSavedPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSavedPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSavedPaymentMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSavedPaymentMethodsQuery(baseOptions?: Apollo.QueryHookOptions<SavedPaymentMethodsQuery, SavedPaymentMethodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SavedPaymentMethodsQuery, SavedPaymentMethodsQueryVariables>(SavedPaymentMethodsDocument, options);
      }
export function useSavedPaymentMethodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SavedPaymentMethodsQuery, SavedPaymentMethodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SavedPaymentMethodsQuery, SavedPaymentMethodsQueryVariables>(SavedPaymentMethodsDocument, options);
        }
export type SavedPaymentMethodsQueryHookResult = ReturnType<typeof useSavedPaymentMethodsQuery>;
export type SavedPaymentMethodsLazyQueryHookResult = ReturnType<typeof useSavedPaymentMethodsLazyQuery>;
export type SavedPaymentMethodsQueryResult = Apollo.QueryResult<SavedPaymentMethodsQuery, SavedPaymentMethodsQueryVariables>;