import React, { forwardRef, useCallback, useEffect, useMemo } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { format, parse } from "date-fns";
import styled from "styled-components";

import {
  Button,
  IconCalendarDate,
  IconClock,
  IconPin,
} from "../../../../atoms";
import { ErrorText } from "../../../../atoms/errors/error-text";
import { Modal } from "../../../../molecules/modal";
import { device } from "../../../../../../utils";
import { DateGroupedAvailability } from "../../hooks/use-availability";
import { FilterFieldDatePicker } from "../../../../molecules/filter/filter-field-date-picker";
import { FilterFieldDropdown } from "../../../../molecules/filter/filter-field-dropdown";
import { FilterFieldInput } from "../../../../molecules/filter/filter-field-input";

const EMSG_FIELD_IS_EMPTY = "The field cannot be empty";
const EMSG_INVALID_POSTCODE = "The entered postcode is not valid.";
const EMSG_NOT_COVERED_POSTCODE =
  "The therapist does not serve in your postcode.";

const ModalStyled = styled(Modal)`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `${theme.spacing.size32} ${theme.spacing.size12}`};
  max-width: 100%;

  @media ${device.tablet} {
    width: 457px;
    padding-left: ${({ theme }) => theme.spacing.size24};
    padding-right: ${({ theme }) => theme.spacing.size24};
  }
`;

const TherapistModalTitle = styled.h2`
  font-family: ${({ theme }) => theme.type.fonts.heading};
  font-size: ${({ theme }) => theme.type.sizes.size22};
  font-weight: 500;
  text-align: center;
  color: ${({ theme }) => theme.colours.textMain};
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.spacing.size18};

  @media ${device.tablet} {
    font-size: ${({ theme }) => theme.type.sizes.size30};
  }
`;

const TherapistModalForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: ${({ theme }) => theme.spacing.size18};
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ErrorInputStyled = styled(ErrorText)`
  margin-top: ${({ theme }) => theme.spacing.size12};
  margin-left: ${({ theme }) => theme.spacing.size18};
`;

const InputInnerContainer = styled.div<{ showBottomRightCorner?: boolean }>`
  display: flex;
  justify-content: stretch;
  box-shadow: ${({ theme }) => theme.shadows.inputField};
  border-radius: 20px;
  height: 40px;
  ${({ showBottomRightCorner = false }) =>
    showBottomRightCorner &&
    `
    border-bottom-right-radius: 0;
  `}
`;

const DatePickerContainer = styled.div`
  flex: 1;

  .calendar {
    left: -135px;

    @media ${device.tablet} {
      left: auto;
    }
  }
`;

const TimeDropdownContainer = styled.div`
  flex: 1;
  border-left: ${({ theme }) => `1px solid ${theme.colours.ruubyLightGrey}`}; ;
`;

const IconClockStyled = styled(IconClock)`
  width: 18px;
  height: 18px;
`;

const TherapistModalButton = styled(Button)`
  width: 100%;

  &:active {
    ${({ theme, disabled }) =>
      !disabled && `background-color: ${theme.colours.ruubyLightGrey}`};
  }
`;

enum InputFieldName {
  postcode = "postcode",
  time = "time",
}

interface TherapistModalInputs extends FieldValues {
  [InputFieldName.postcode]: string;
  [InputFieldName.time]: Date | string;
  [key: string]: any;
}

const FilterFieldPostcode = forwardRef<
  HTMLInputElement,
  React.ComponentProps<typeof FilterFieldInput>
>((props, ref): JSX.Element => <FilterFieldInput innerRef={ref} {...props} />);

interface Props {
  isShown: boolean;
  postcode?: string;
  therapistAvailability: DateGroupedAvailability;
  selectedDate?: string;
  selectedTime?: string;
  isLoading?: boolean;
  isValidPostcode(postcode: string): boolean;
  isCoveredPostcode(postcode: string): boolean;
  onClose(): void;
  onSelectDate(date: string): void;
  onSelectTime(time: string): void;
  onRemoveTime(): void;
  onSelectPostcode(postcode: string): void;
  onSubmit(data: TherapistModalInputs): void;
}

export const TherapistBookingModal = ({
  isShown,
  postcode,
  selectedDate,
  selectedTime,
  isLoading,
  therapistAvailability,
  isValidPostcode,
  isCoveredPostcode,
  onClose,
  onSelectDate,
  onSelectTime,
  onRemoveTime,
  onSelectPostcode,
  onSubmit,
}: Props): JSX.Element => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    defaultValues: useMemo(
      () => ({
        postcode: postcode ?? "",
        date: selectedDate ?? "Date",
        time: selectedTime ?? "Time",
      }),
      [postcode],
    ),
  });

  useEffect(() => {
    reset({
      postcode,
    });
  }, [postcode]);

  const timeSlots = selectedDate
    ? therapistAvailability[selectedDate] ?? []
    : [];
  const timeItems = useMemo(
    () =>
      timeSlots.map((time) => [
        time,
        format(parse(time, "HH:mm", new Date()), "h:mm a"),
      ]),
    [timeSlots],
  ) as [string, string][];

  const handleClose = useCallback((): void => {
    onClose();
    reset({
      postcode: postcode ?? "",
      date: selectedDate ?? "Date",
      time: selectedTime ?? "Time",
    });
  }, [onClose, reset]);

  const handleSelectDate = useCallback(
    (date: string): void => {
      onSelectDate(date);
      onRemoveTime();
    },
    [onSelectDate, onRemoveTime],
  );

  const isSubmitDisabled = useCallback(
    (): boolean =>
      !postcode ||
      !isValidPostcode(postcode) ||
      !isCoveredPostcode(postcode) ||
      !selectedDate ||
      !selectedTime,
    [postcode, selectedDate, selectedTime, isValidPostcode, isCoveredPostcode],
  );

  return (
    <ModalStyled
      isShow={isShown}
      onClose={handleClose}
      isOverlayClose
      isCloseIconShown={false}
    >
      <TherapistModalTitle>Continue to Checkout</TherapistModalTitle>
      <TherapistModalForm onSubmit={handleSubmit(onSubmit)}>
        <InputContainer>
          <InputInnerContainer>
            <FilterFieldPostcode
              id="postcode"
              icon={<IconPin />}
              type="text"
              isUppercase
              placeholder="Postcode"
              {...register(InputFieldName.postcode, {
                required: EMSG_FIELD_IS_EMPTY,
                validate: (postcode) => {
                  if (!isValidPostcode(postcode)) {
                    return EMSG_INVALID_POSTCODE;
                  }
                  if (!isCoveredPostcode(postcode)) {
                    return EMSG_NOT_COVERED_POSTCODE;
                  }
                },
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  const newPostcode = e.target.value.trim().toUpperCase();

                  if (
                    isValidPostcode(newPostcode) &&
                    isCoveredPostcode(newPostcode)
                  ) {
                    onSelectPostcode(newPostcode);
                  }
                },
              })}
            />
          </InputInnerContainer>
          {errors && errors[InputFieldName.postcode] && (
            <ErrorInputStyled>
              {errors[InputFieldName.postcode]?.message}
            </ErrorInputStyled>
          )}
        </InputContainer>
        <InputContainer>
          <InputInnerContainer showBottomRightCorner={true}>
            <DatePickerContainer>
              <FilterFieldDatePicker
                icon={<IconCalendarDate />}
                selectableDates={Object.keys(therapistAvailability)}
                selectedDate={selectedDate}
                onSelectDate={handleSelectDate}
              />
            </DatePickerContainer>
            <TimeDropdownContainer>
              <FilterFieldDropdown
                selectedValue={selectedTime}
                placeholder="Time"
                items={timeItems}
                icon={<IconClockStyled />}
                isDisabled={!selectedDate}
                onSelect={onSelectTime}
              />
            </TimeDropdownContainer>
          </InputInnerContainer>
          {errors && errors[InputFieldName.time] && (
            <ErrorInputStyled>
              {errors[InputFieldName.time]?.message}
            </ErrorInputStyled>
          )}
        </InputContainer>
        <TherapistModalButton
          type="submit"
          size="small"
          disabled={isSubmitDisabled()}
        >
          {isLoading ? "LOADING" : "Book now"}
        </TherapistModalButton>
      </TherapistModalForm>
    </ModalStyled>
  );
};
