import * as React from "react";
import { Transition } from "react-transition-group";
import styled from "styled-components";

import { Button, IconX } from "../../../components/atoms";
import { TopLogo } from "../../../components/molecules";
import { IMenuItems, menuData } from "../../../static/menu";
import { treatmentSubMenuData } from "../../../static/treatment-submenu";
import { switzerlandSubMenuData } from "../../../static/switzerland-submenu";

import {
  INavUserProps,
  IProps as INavProps,
  setCategoryItem,
  setCategorySubItem,
} from "../navigation";
import { NavigationItem } from "../navigation/components";
import { useCallback, useState } from "react";

interface IProps extends INavUserProps, INavProps {
  isMobileMenuOpen: boolean;
  onClose(): void;
  onDownload(): void;
}

const duration = {
  enter: 0,
  exit: 0,
};

const mobileMenuStyle = {
  transition: `all 300ms`,
};

const mobileMenuStyles = {
  entered: { opacity: 1, left: 0 },
  entering: { opacity: 0, left: "-500px" },
  exited: { opacity: 0 },
  exiting: { opacity: 1 },
};

const mobileMenuBackdropStyles = {
  entered: { opacity: 1 },
  entering: { opacity: 0 },
  exited: { opacity: 0 },
  exiting: { opacity: 1 },
};

const MobileMenuWrapper = styled.div``;

const MobileMenuContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  left: 0;
  height: 100%;
  width: 250px;
  padding: 20px;
  background: white;
  overflow-x: auto;
  overflow-x: hidden;
  z-index: 9999;
`;

const MobileMenuPart = styled.div<{ level: number }>`
  transition: all 0.5s ease-in-out;
  position: absolute;
  margin-top: 25px;
  left: ${({ level }): number => 20 + 250 * level}px;
`;

const MobileMenuBackdrop = styled.div`
  position: fixed;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9998;
`;

const MobileMenuLogoBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const MobileMenuCloseIcon = styled.div`
  > svg {
    width: 30px;
    height: 30px;
  }
`;

const MobileMenuButton = styled(Button)`
  margin-top: 20px;
  width: 100%;
`;

const LevelBackLink = styled.div`
  color: ${({ theme }): string => theme.colours.darkGrey};
  font-weight: 600;
  font-size: 1.35rem;
  margin-top: 25px;
  margin-bottom: -10px;
  span {
    margin-right: 5px;
  }

  span:after {
    content: "<";
  }
`;

const MobileMenu: React.FC<IProps> = (props) => {
  const [subCategories, setSubCategories] = useState<IMenuItems[]>([]);
  const [currentLevel, setCurrentLevel] = useState(0);
  const setLevel0 = useCallback(() => setCurrentLevel(0), []);
  const setLevel1 = useCallback(() => setCurrentLevel(1), []);

  const goToSubCategory = useCallback((category: string) => {
    if (category === "treatments") {
      setSubCategories(treatmentSubMenuData);
      return setLevel1();
    } else if (category === "switzerland") {
      setSubCategories(switzerlandSubMenuData);
      return setLevel1();
    } else {
      return props.onClose();
    }
  }, []);

  const getNavigationItemStyle = (index: number, length: number) => {
    return index === length - 1 ? { marginBottom: "20px" } : undefined;
  };

  const getSubCategoryItems = useCallback(() => {
    const categories = subCategories.map((category, index) => {
      const navigationItems = (
        <NavigationItem
          key={index}
          onClick={props.onClose}
          style={getNavigationItemStyle(index, subCategories.length)}
        >
          {setCategorySubItem(category)}
        </NavigationItem>
      );

      return navigationItems;
    });
    return (
      <>
        <LevelBackLink onClick={setLevel0}>
          <span /> Back
        </LevelBackLink>
        {categories}
      </>
    );
  }, [subCategories, setCategoryItem]);

  const menuItems = menuData.map((category, index) => {
    if (!props.isLoggedIn && category.id === "login") {
      return undefined;
    }
    const navigationItems = (
      <NavigationItem key={index} onClick={() => goToSubCategory(category.id)}>
        {setCategoryItem(category, props)}
      </NavigationItem>
    );
    return navigationItems;
  });

  const onDownloadButtonClick = () => {
    props.onDownload();
    props.onClose();
  };

  return (
    <Transition in={props.isMobileMenuOpen} timeout={duration}>
      {(state) =>
        props.isMobileMenuOpen && (
          <MobileMenuWrapper>
            <MobileMenuContent
              style={{
                ...mobileMenuStyle,
                ...mobileMenuStyles[state],
              }}
            >
              <MobileMenuLogoBar>
                <TopLogo onClick={props.onClose} />
                {currentLevel === 0 && (
                  <MobileMenuCloseIcon onClick={props.onClose}>
                    <IconX />
                  </MobileMenuCloseIcon>
                )}
              </MobileMenuLogoBar>
              <MobileMenuPart level={currentLevel * -1}>
                <>
                  {menuItems}
                  {!props.isLoggedIn && (
                    <MobileMenuButton
                      size="small"
                      background="grey"
                      to="/login"
                      onClick={props.onClose}
                    >
                      Log In
                    </MobileMenuButton>
                  )}
                  <MobileMenuButton
                    size="small"
                    background="blue"
                    onClick={onDownloadButtonClick}
                  >
                    Get the app
                  </MobileMenuButton>
                </>
              </MobileMenuPart>
              <MobileMenuPart level={currentLevel * -1 + 1}>
                {getSubCategoryItems()}
              </MobileMenuPart>
            </MobileMenuContent>
            <MobileMenuBackdrop
              style={{
                ...mobileMenuStyle,
                ...mobileMenuBackdropStyles[state],
              }}
              onClick={props.onClose}
            />
          </MobileMenuWrapper>
        )
      }
    </Transition>
  );
};

export { MobileMenu };
