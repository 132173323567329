import React from "react";

import { FilterFieldInput } from "../../../../molecules/filter/filter-field-input";
import { FieldWrapper } from "../field-wrapper";
import { IconPin } from "../../../../atoms";

interface Props {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
}

export const PostcodeField = ({ onChange, value }: Props): JSX.Element => {
  return (
    <FieldWrapper>
      <FilterFieldInput
        icon={<IconPin />}
        type="text"
        value={value}
        placeholder="Postcode"
        autoFocus={true}
        onChange={onChange}
        isUppercase
      />
    </FieldWrapper>
  );
};
