import React, { useCallback } from "react";
import { Transition } from "react-transition-group";
import {
  ENTERED,
  ENTERING,
  EXITED,
  EXITING,
  TransitionProps,
  TransitionStatus,
} from "react-transition-group/Transition";
import styled from "styled-components";

import { IconX, Text } from "../../../atoms";
import { createPortal } from "react-dom";

const ModalOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(21, 22, 20, 0.5);
  z-index: 1000;
`;

const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TitleText = styled(Text)`
  padding-bottom: 20px;
`;

const CloseIcon = styled.div`
  margin-left: auto;
  padding-bottom: 20px;

  svg {
    width: 24px;
    height: 24px;
    fill: ${({ theme }) => theme.colours.ruubyLightGrey};
    cursor: pointer;
    transition: ${({ theme }) => `fill ${theme.transition.linear03}`};

    &:hover {
      fill: ${({ theme }) => theme.colours.ruubyBlack};
    }
  }
`;

const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 40px;
  background: white;
  border-radius: 8px;
  z-index: 1001;

  @media screen and (max-width: 992px) {
    width: 90vw;
    padding: 20px;
  }
`;

const duration: TransitionProps["timeout"] = {
  enter: 0,
  exit: 0,
};

const modalStyle: React.CSSProperties = {
  transition: `opacity 300ms`,
};

const modalStyles: Partial<Record<TransitionStatus, React.CSSProperties>> = {
  [ENTERED]: { opacity: 1 },
  [ENTERING]: { opacity: 0 },
  [EXITED]: { opacity: 0 },
  [EXITING]: { opacity: 1 },
};

interface Props {
  title?: string;
  modalText?: string;
  children?: React.ReactNode;
  className?: string;
  isShow: boolean;
  isOverlayClose?: boolean;
  isCloseIconShown?: boolean;
  usePortal?: boolean;
  onClose(): void;
}

export const Modal = ({
  title,
  children,
  className,
  isOverlayClose,
  isCloseIconShown = true,
  isShow,
  modalText,
  onClose,
  usePortal = false,
}: Props): JSX.Element => {
  const closeOnOverlayClick = useCallback(
    (state: string) => {
      if (state !== "entering" && isOverlayClose) {
        onClose();
      } else {
        return;
      }
    },
    [isOverlayClose, onClose],
  );

  if (isShow) {
    setTimeout(() => {
      window.document.body.style.overflow = "hidden";
    });
  } else {
    window.document.body.style.overflow = "";
  }

  const content = (
    <Transition in={isShow} timeout={duration} mountOnEnter unmountOnExit>
      {(state) => (
        <>
          <ModalOverlay
            style={{
              ...modalStyle,
              ...modalStyles[state],
            }}
            onClick={() => closeOnOverlayClick(state)}
          />
          <ModalWrapper
            className={className}
            style={{
              ...modalStyle,
              ...modalStyles[state],
            }}
          >
            <ModalHeaderContainer>
              {title && (
                <TitleText size="18" weight="semibold">
                  {title}
                </TitleText>
              )}
              {isCloseIconShown && (
                <CloseIcon>
                  <div onClick={onClose}>
                    <IconX />
                  </div>
                </CloseIcon>
              )}
            </ModalHeaderContainer>
            {children ? (
              children
            ) : (
              <Text size="18" weight="semibold">
                {modalText}
              </Text>
            )}
          </ModalWrapper>
        </>
      )}
    </Transition>
  );

  return usePortal ? createPortal(content, document.body) : content;
};
