import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { rem } from "polished";
import styled from "styled-components";

import {
  IconCalendar,
  IconClock,
  IconPin,
  IconSearch,
  Text,
} from "../../../../../../atoms";
import { FilterFieldDatePicker } from "../../../../../../molecules/filter/filter-field-date-picker";
import { FilterFieldDropdown } from "../../../../../../molecules/filter/filter-field-dropdown";
import { device } from "../../../../../../../../utils";
import { ScreenClass, useScreenClass } from "react-grid-system";
import { FilterFields } from "../../../../hooks/use-search-filter";
import { DropdownItem } from "../../../../../../molecules/filter/filter-field-dropdown/types";
import { PostcodeModal } from "../postcode-modal";

const FilterBarContainer = styled.div<{
  topOffset: number;
  hasBorderBottomLeftRadius: boolean;
  hasBorderBottomRightRadius: boolean;
}>`
  position: sticky;
  top: ${({ topOffset }) => `${topOffset + 16}px`};
  display: flex;
  height: ${rem("40px")};
  width: 100%;
  background-color: ${({ theme }) => theme.colours.background.white};
  box-shadow: ${({ theme }) => theme.shadows.therapistFilter};
  border-radius: ${rem("20px")};

  ${({ hasBorderBottomLeftRadius }) =>
    !hasBorderBottomLeftRadius && `border-bottom-left-radius: 0;`}
  ${({ hasBorderBottomRightRadius }) =>
    !hasBorderBottomRightRadius && `border-bottom-right-radius: 0;`}

  & > div:not(:last-child) {
    border-right: ${({ theme }) => theme.colours.inputBorderColor} 1px solid;
  }

  @media ${device.tablet} {
    max-width: ${rem("720px")};
    top: ${({ topOffset }) => `${topOffset + 18}px`};
  }
`;

const FilterBarItem = styled.div<{ isHiddenOnMobile?: boolean }>`
  flex: 1;
  display: flex;
  align-items: center;

  ${({ isHiddenOnMobile }) =>
    isHiddenOnMobile &&
    `
    display: none;
  
    @media ${device.tablet} {
      display: flex;
    }
  `}

  .calendar {
    right: 135px;

    @media ${device.tablet} {
      right: 50%;
    }
  }
`;

const IconCalendarStyled = styled(IconCalendar)`
  width: ${rem("16px")};
  height: ${rem("18px")};
`;

const IconClockStyled = styled(IconClock)`
  width: ${rem("18px")};
  height: ${rem("18px")};
`;

const FeaturedCategory = styled(Text)`
  text-align: center;
  width: 100%;
  cursor: pointer;
`;

const PostcodeButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0 1rem;
  width: 100%;
  background: transparent;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colours.darkGrey};

  &:hover {
    color: ${({ theme }) => theme.colours.black};
  }

  &:focus {
    outline: none;
  }

  svg {
    margin-right: 0.5rem;
  }
`;


interface Props {
  selectedFilterFields?: FilterFields;
  categoryOptions: [string, string][];
  timeOptions: DropdownItem[];
  maxDaysRange?: number;
  topOffset: number;
  onChangeCategory(category: string): void;
  onChangePostcode: (postcode: string) => void;
  onChangeDate: (date: string) => void;
  onChangeTime: (time: string) => void;
}

export const FilterBar = ({
  selectedFilterFields,
  categoryOptions,
  timeOptions,
  maxDaysRange,
  topOffset,
  onChangeCategory,
  onChangePostcode,
  onChangeDate,
  onChangeTime,
}: Props): JSX.Element => {
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const [isTimeOpen, setIsTimeOpen] = useState(false);
  const screenClass = useScreenClass();
  const history = useHistory();

  const isDesktop = (
    ["md", "lg", "xl", "xxl", "xxxl"] as ScreenClass[]
  ).includes(screenClass);

  const [isPostcodeModalOpen, setIsPostcodeModalOpen] = useState(false);

  const handlePostcodeSubmit = useCallback(
    (postcode: string) => {
      onChangePostcode(postcode);
      setIsPostcodeModalOpen(false);
    },
    [onChangePostcode],
  );

  const redirectToHomeFeatured = useCallback(() => {
    history.push("/#featured-treatments");
  }, []);

  return (
    <>
      <FilterBarContainer
        topOffset={topOffset}
        hasBorderBottomLeftRadius={!isCategoryOpen}
        hasBorderBottomRightRadius={!isTimeOpen}
      >
        <FilterBarItem>
          {selectedFilterFields?.treatmentUrn ? (
            <FeaturedCategory onClick={redirectToHomeFeatured}>Featured</FeaturedCategory>
          ) : (
            <FilterFieldDropdown
              selectedValue={selectedFilterFields?.category}
              placeholder="Category"
              items={categoryOptions}
              icon={isDesktop && <IconSearch />}
              onSelect={onChangeCategory}
              onChangeOpen={setIsCategoryOpen}
            />
          )}
        </FilterBarItem>
        <FilterBarItem>
          <PostcodeButton onClick={() => setIsPostcodeModalOpen(true)}>
            {isDesktop && <IconPin />}
            {selectedFilterFields?.postcode?.toUpperCase()}
          </PostcodeButton>
        </FilterBarItem>
        <FilterBarItem>
          <FilterFieldDatePicker
            maxDaysRange={maxDaysRange}
            selectedDate={selectedFilterFields?.date}
            icon={isDesktop && <IconCalendarStyled />}
            onSelectDate={onChangeDate}
          />
        </FilterBarItem>
        <FilterBarItem isHiddenOnMobile={true}>
          <FilterFieldDropdown
            selectedValue={selectedFilterFields?.time}
            items={timeOptions}
            icon={isDesktop && <IconClockStyled />}
            onSelect={onChangeTime}
            onChangeOpen={setIsTimeOpen}
          />
        </FilterBarItem>

      </FilterBarContainer>
      <PostcodeModal
        value={selectedFilterFields?.postcode?.toUpperCase()}
        isVisible={isPostcodeModalOpen}
        onClose={() => setIsPostcodeModalOpen(false)}
        onSubmit={handlePostcodeSubmit}
      />
    </>
  );
};
