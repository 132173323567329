import React, { useCallback, useState, useEffect } from "react";
import styled from "styled-components";
import { Modal } from "../../../../../../molecules/modal";
import { getDistrictCode } from "@ruuby/common/lib/utils/postcodes";
import { PostcodeField } from "../../../../../../organisms/search-widget/components/postcode-field";
import { device } from "../../../../../../../../utils";
import { ErrorText } from "../../../../../../atoms/errors/error-text";

const ERROR_MESSAGE_INVALID_POSTCODE = "The entered postcode is not valid.";

const ModalStyled = styled(Modal)`
  display: flex;
  flex-direction: column;
  max-width: 100%;

  @media ${device.tablet} {
    width: 457px;
    padding-left: ${({ theme }) => theme.spacing.size24};
    padding-right: ${({ theme }) => theme.spacing.size24};
  }
`;

const ModalTitle = styled.h2`
  font-family: ${({ theme }) => theme.type.fonts.heading};
  font-size: ${({ theme }) => theme.type.sizes.size22};
  font-weight: 500;
  text-align: center;
  color: ${({ theme }) => theme.colours.textMain};
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.spacing.size18};

  @media ${device.tablet} {
    font-size: ${({ theme }) => theme.type.sizes.size30};
  }
`;

const ModalForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spacing.size10};
`;

const ErrorMessageContainer = styled(ErrorText)`
  min-height: ${({ theme }) => theme.spacing.size14};
  margin-left: ${({ theme }) => theme.spacing.size18};
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
`;

const StyledButton = styled.button<{ disabled?: boolean }>`
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colours.mediumGrey2 : theme.colours.darkGrey};
  color: ${({ theme }) => theme.colours.white};
  padding: ${({ theme }) => `${theme.spacing.size12}`};
  border-radius: 20px;
  border: none;
  width: 100%;
  font-size: ${({ theme }) => theme.type.sizes.size16};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

interface Props {
  value?: string;
  isVisible: boolean;
  onClose: () => void;
  onSubmit: (postcode: string) => void;
}

export const PostcodeModal = ({
  value,
  isVisible,
  onClose,
  onSubmit,
}: Props): JSX.Element => {

  const [postcode, setPostcode] = useState<string>("");
  const [error, setError] = useState("");
  const [enabledSubmit, setEnabledSubmit] = useState(false);

  const isValidPostcode = useCallback(
    (selectedPostcode: string): boolean =>
      Boolean(getDistrictCode(selectedPostcode)),
    [],
  );

  const validatePostcode = useCallback(
    (selectedPostcode: string): boolean => {
      if (!isValidPostcode(selectedPostcode)) {
        return false;
      } else {
        setPostcode(selectedPostcode);
        return true;
      }
    },
    [isValidPostcode],
  );

  const validateSubmit = useCallback(
    (nextValue: string) => {
      const isValid = validatePostcode(nextValue);
      setEnabledSubmit(isValid);
      if (!isValid && nextValue.length > 1) setError(ERROR_MESSAGE_INVALID_POSTCODE);
      else setError("");
    },
    [validatePostcode],
  );

  useEffect(() => {
    setError("");
    setPostcode(value ?? "");
    validateSubmit(value ?? "");
  }, [value, validateSubmit, isVisible]);

  const handleChangeText = useCallback(
    (text: string) => {
      const textUpper = text.toUpperCase();
      setPostcode(textUpper);
      validateSubmit(textUpper);
    },
    [validateSubmit],
  );

  const handleSubmit = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const uppercasePostcode = postcode.toUpperCase();
    onSubmit(uppercasePostcode);
    e.preventDefault();
  }, [postcode, onSubmit]);

  return (
    <ModalStyled
      isShow={isVisible}
      onClose={onClose}
      isOverlayClose
      isCloseIconShown={false}
      usePortal
    >
      <ModalTitle>Tell us where you are</ModalTitle>
      <ModalForm>
        <PostcodeField onChange={(e) => handleChangeText(e.target.value)} value={postcode} />
        <ErrorMessageContainer>{error}</ErrorMessageContainer>
        <ButtonContainer>
          <StyledButton onClick={(e) => handleSubmit(e)} disabled={!enabledSubmit}>
            DONE
          </StyledButton>
        </ButtonContainer>
      </ModalForm>
    </ModalStyled>
  );
};